import { Suspense } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Provider } from "react-redux";
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
} from "react-router-dom";

import { ErrorFallback } from "@/components/ErrorFallback";
import { pageAtRouteMap } from "@/pages";
import { routeNames, routes } from "@/routes";
import { store } from "@/store";

/**
 * Create an array of objects with path and the component properties
 */
const pages = routeNames.map((routeName) => {
  const Component = pageAtRouteMap[routeName];
  return { path: routes[routeName].path, Component };
});

/**
 * Component representing the main application
 *
 * @returns rendered application
 */
const App = () => {
  return (
    <Provider store={store}>
      <ErrorBoundary fallback={<ErrorFallback />}>
        <BrowserRouter>
          <Routes>
            <Route
              index
              element={<Navigate to={routes.dashboard.path} replace />}
            />
            <Route
              element={
                /* Used because app pages are lazy loaded */
                <Suspense>
                  <Outlet />
                </Suspense>
              }
            >
              {pages.map(({ path, Component }) => (
                <Route key={path} path={path} element={<Component />} />
              ))}
            </Route>
          </Routes>
        </BrowserRouter>
      </ErrorBoundary>
    </Provider>
  );
};

export { App };
