import { configureStore } from "@reduxjs/toolkit";

import { userReducer } from "@/store/slices/user.slice";

/**
 * Create and configure redux store using the `configureStore` (more info: https://redux.js.org/usage/configuring-your-store)
 */
export const store = configureStore({
    reducer: {
        user: userReducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware(), // explicitly use `getDefaultMiddleware()`
    enhancers: (getDefaultEnhancers) => getDefaultEnhancers(), // explicitly use `getDefaultEnhancers()`
});

/**
 * Infer the `RootState` type from the store
 */
export type RootState = ReturnType<typeof store.getState>;
/**
 * Infer the `AppDispatch` type from the store
 */
export type AppDispatch = typeof store.dispatch;
