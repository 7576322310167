import { lazy } from "react";

import { PageAtRouteMap } from "@/pages/pages.types";

export * from "@/pages/pages.types";

/**
 * Define DashboardPage as a lazy loaded component from its folder
 */
export const DashboardPage = lazy(() => import("@/pages/DashboardPage"));
/**
 * Define HelloWorldPage as a lazy loaded component from its folder
 */
export const HelloWorldPage = lazy(() => import("@/pages/HelloWorldPage"));

/**
 * Create an object where routes are associated the their page components
 */
export const pageAtRouteMap = {
    dashboard: DashboardPage,
    helloWorld: HelloWorldPage,
} satisfies Partial<PageAtRouteMap>;
