import { Route } from "@/routes/routes.types";

/**
 * Create routes object where each route has its path and params
 */
export const routes = {
    dashboard: {
        path: "/dashboard",
    },
    helloWorld: {
        path: "/hello-world",
    },
} satisfies Record<string, Route>;

/**
 * Define Routes type representing all the defined routes
 */
export type Routes = typeof routes;
